<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <h1>호전환</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main>
                <!--span>
                    부모로 부터 수신 메세지 : {{message}}
                </span>
                <button type="button" @click="sendMsgToParent">전송</button-->
                <div class="jh-cols">                    
                    <!--왼쪽프레임-->
                    <div class="jh-form-wrap is-col-fix" style="width: 400px;">
                        <div class="jh-ui-header">
                            <h3 class="has-bullet">담당부서 연결</h3>
                            <div class="is-right">
                                <p class="jh-info-txt is-red is-txt-red is-mb-5">3자통화 불가</p>
                            </div>
                        </div>
                        <div class="jh-list-transfer">
                            <v-btn
                                v-for="(item, index) in cdIVR06"
                                :key="item.index"
                                class="jh-btn is-multi"
                                :class="{'is-org' : selectedBtn === item }"
                                @click.stop="btnClick(item)"
                            > {{item.info1}}<span class="is-num is-mt-5">{{mixin_newPhoneFormat(item.info2)}}</span> </v-btn>
                        </div>
                        <div class="jh-ui-header is-mt-15">
                            <h3 class="has-bullet">그룹별 연결</h3>
                        </div>
                        <div class="jh-list-transfer">
                            <v-btn
                                v-for="(item, index) in cdIVR07"
                                :key="item.index"
                                class="jh-btn is-multi"
                                :class="{'is-org' : selectedBtn === item }"
                                @click.stop="btnClick(item)"
                            > {{item.info1}} </v-btn>
                        </div>
                        <div class="jh-ui-header is-mt-15">
                            <h3 class="has-bullet">영업점 연결</h3>
                        </div>
                        <div class="jh-list-transfer" style="height: 156px;">
                            <v-btn
                                v-for="(item, index) in cdIVR08"
                                :key="item.index"
                                class="jh-btn is-multi is-disable-white"
                                :class="{'is-org' : selectedBtn === item }"
                                :disabled="item.disabled"
                                @click.stop="btnClick(item)"
                            > {{item.info1}}<span class="is-num is-mt-5">{{mixin_newPhoneFormat(item.info2)}}</span> </v-btn>
                        </div>
                    </div>
                    <!--//왼쪽프레임-->

                    <!--오른쪽프레임-->
                    <div class="jh-card">
                        <div class="jh-card-header">
                            <h1>상담사 연결</h1>
                            <div class="is-right">
                                <p class="jh-info-txt is-blue is-txt-blue">3자통화 가능</p>
                            </div>
                        </div>
                        <div class="jh-card-body is-jh-15 is-pr-15 is-pb-0">
                            <div class="jh-ui-header is-mt-5">
                                <h3 class="has-bullet">직접 연결</h3>
                            </div>
                            <div class="jh-block jh-cols">
                                <label class="jh-label">내선번호</label>
                                <v-text-field class="jh-form is-md is-col-fix" style="width: 100px;" v-model="inlineNo" @keydown="keyDownInp(inlineNo)" @change="changeInp(inlineNo)"></v-text-field>
                                <v-checkbox class="jh-check" v-model="chkConf" value="Y" label="확인연결(3자)" @click="confChkClick(chkConf)"></v-checkbox>
                            </div>
                            <div class="jh-ui-header is-mt-15">                                
                                <div class="is-left">
                                    <h3 class="has-bullet">선택 연결</h3>
                                    <v-btn class="jh-btn is-icon is-ml-0"  @click="cnslSttReset"><i class="jh-icon-refresh is-red"></i></v-btn>
                                </div>
                                <div class="is-right">
                                    <label class="jh-label">상태</label>
                                    <v-select class="jh-form" style="width: 100px;" 
                                    v-model="USER_STT" @change="chgState"
                                    :items="mixin_common_code_get(this.common_code, 'IVR09', '전체')"
                                    ></v-select>
                                    <label class="jh-label">상담사명</label>
                                    <v-text-field class="jh-form" style="width: 120px;" v-model="USER_NAME" @keyup.enter="srchCnsl(USER_NAME)">
                                        <template v-slot:append>
                                            <v-btn class="jh-btn is-icon" @click="srchCnsl(USER_NAME)"><i class="jh-icon-search is-blue"></i></v-btn>
                                          </template>
                                    </v-text-field>
                                </div>
                            </div>
                            <v-data-table
                                ref="grid"
                                class="jh-grid"
                                height="270"
                                :headers="headers"
                                :items="rowNum"
                                :items-per-page="50"
                                :search="search"
                                fixed-header
                                item-key="index"
                                singleSelect
                                hide-default-footer
                                @click:row="onClickRow">
                            </v-data-table>
                        </div>
                    </div>
                    <!--//오른쪽프레임-->
                </div>
            </main>
            <footer>
                <v-btn class="jh-btn is-lg is-fill" style="width: 140px;" :disabled="confYn" @click="fnConfBtnClick">3자통화</v-btn>
                <v-btn class="jh-btn is-lg is-main" style="width: 140px;" :disabled="trnsYn" @click="fnTransfer">호전환</v-btn>
                <v-btn class="jh-btn is-lg is-del"  style="width: 140px;" :disabled="cnnClsYn" @click="fnConnClose">연결시도 종료</v-btn>
            </footer>
        </div>        
    </v-app>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { eventBus } from "@/store/eventBus.js";
  
export default {
    name: "MENU_P100401", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
        transfer: {
          type: Object,
          value: {},
        },
    },
    mounted() {
        const self = this;

        this.callCommonCode();
        this.cnslSttReset();

        if(opener) {
            console.log("opener is valid!!!");

            opener.g_winPopup = this;
        }

        // let query = window.location.search;
        // if(query == null || query == "") {
        //     let nativeUrl = window.location.href;
        //     nativeUrl = nativeUrl.replace('/#', '');

        //     let url = new URL(nativeUrl);
        //     const urlParams = url.searchParams;

        //     this.param1 = urlParams.get('param1');
        //     this.param2 = urlParams.get('param2');

        //     console.log("opener param1!!!" + param1);
        //     console.log("opener param2!!!" + param2);
        // }else {
        //     let param = new URLSearchParams(query);
        //     if( param != null) {
        //         this.param1 = param.get('param1');
        //         this.param2 = param.get('param2');
        //     } else {
        //         this.common_alert(`cannot get query`, 'error');
        //     }
        // }
    },
    data() {
        return {
            common_code: [], //공통코드
            transferNum: '',
            confYn: true,
            trnsYn: true,
            cnnClsYn: true,

            cdIVR06: [],
            cdIVR07: [],
            cdIVR08: [],

            SSTransferYn: false,
            groupCd: '',

            chkConf: 'Y',
            inlineNo: '',
            selectedRow: [],

            teamList: [],

            selectedBtn: null,

            message: '',
            search: '대기',
            USER_STT: '대기',
            USER_NAME: '',

            param1: null,
            param2: null,

            Btn01: [
                { text: "해외주식", number: "02-3779-8888", active: false, group: "A"},
                { text: "해외선물", number: "02-3779-8330", active: false, group: "A"},
            ],
            Btn02: [
                { text: "고객만족센터A", number: "65218", active: false, group: "B" },
                { text: "고객만족센터B", number: "65219", active: false, group: "B" },
            ],
            Btn03: [
                { text: "영업부"       , number: "02-3779-8811", active: false, group: "C", disabled: false },
                { text: "테헤란금융센터", number: "02-3779-8700", active: false, group: "C", disabled: false },
                { text: "강북금융센터"  , number: "02-3779-8777", active: false, group: "C", disabled: false },
                { text: "준비중"  , number: "", active: false, group: "C", disabled: true },
            ],
            headers: [
                { text: '상담사명', value: 'CNSL_NAME' , align: 'center', width: '120px', },
                { text: '사번'    , value: 'CNSL_USID', align: 'center', width: '120px', },
                { text: '전화번호', value: 'CNSL_TNO' , align: 'center', width: '120px', },
                { text: '상태'    , value: 'STATE_NM'    , align: 'center', },
            ],
            items: [
                { CNSL_NAME: "일지매", CNSL_USID: "9991", CNSL_TNO: "1191", STATE_NM: "대기중", STATE: "NOT_READY" },
                { CNSL_NAME: "이상순", CNSL_USID: "9992", CNSL_TNO: "1192", STATE_NM: "대기중", STATE: "NOT_READY" },
                { CNSL_NAME: "김삼순", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "대기중", STATE: "NOT_READY" },
                { CNSL_NAME: "준비원", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "준비", STATE: "READY" },
                { CNSL_NAME: "준비투", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "준비", STATE: "READY" },
                { CNSL_NAME: "좀비삼", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "준비", STATE: "READY" },
                { CNSL_NAME: "통화원", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "통화중", STATE: "TALKING" },
                { CNSL_NAME: "통화투", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "통화중", STATE: "TALKING" },
                { CNSL_NAME: "통화삼", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "통화중", STATE: "TALKING" },
                { CNSL_NAME: "로그일", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "로그아웃", STATE: "LOGOUT" },
                { CNSL_NAME: "로그이", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "로그아웃", STATE: "LOGOUT" },
                { CNSL_NAME: "로그삼", CNSL_USID: "9993", CNSL_TNO: "1193", STATE_NM: "로그아웃", STATE: "LOGOUT" }
            ],
        }
    },
    computed: {
        rowNum() {
            return this.items.map(
                (items, index) => ({
                ...items,
                index: index + 1
            }))
        },
    },
    methods: {
        async callCommonCode(){
            let codeName = ['IVR06','IVR07','IVR08','IVR09'];
            this.common_code = await this.mixin_common_code_get_all(codeName);

            this.cdIVR06 = this.mixin_common_code_get(this.common_code, 'IVR06');
            this.cdIVR07 = this.mixin_common_code_get(this.common_code, 'IVR07');
            this.cdIVR08 = this.mixin_common_code_get(this.common_code, 'IVR08');

            this.setNumber();
        },
        setNumber() {
            let leng = 4 -this.cdIVR08.length;

            for(var i=0; i<leng; i++){
                let item = {
                    info1: "준비중"  , text: "", active: false, group: "C", disabled: true
                };
                this.cdIVR08.push(item);
            }
        }, 
        close(){
            self.close();
        },
        sendMsgToParent(msg, val) {
            this.sendToOpener({
                evt:  msg,
                Val1: val,
                message: 'P100401'
            });
        },

        sendToOpener( sendObj ) {
            if( opener == null) {
                return;
            }

            let sendStr = JSON.stringify(sendObj);

            window.opener.postMessage( sendStr, '*');
        },
        cnslSttReset() {
            this.sendMsgToParent("TEAM", "5000");
        },
        calledFromOpener( evt ) {
            console.log("receiveFromOpener -------");
            this.message = evt.msg;
            this.teamList = evt.sData;
        },
        srchCnsl(val) {

            if(!this.mixin_isEmpty(val)) {
                this.search = val;
            } else {
                this.search = this.USER_STT;
            }
        },
        onClickRow: function (item, row) {
            this.selectedBtn = null;
            this.trnsYn   = true;  //호전환
            this.cnnClsYn = true;  //연결종료
            this.groupCd = "";

            if(this.selectedId===item.index){
                row.select(false);
                this.selectedId="";
                this.selectedRow = [];
                this.confYn   = true;  //3자통화

                this.transferNum = "";
            }else{
                this.selectedRow = row;
                row.select(true);
                this.selectedId=item.index;
                this.selectedBtn = null;

                if(this.mixin_isEmpty(item.CNSL_TNO)) {
                    this.transferNum = "";
                    this.common_alert(`연결할 전화번호가 없습니다.`, 'info');
                    return false;
                }

                //3자통화 체크
                if(this.chkConf == "Y") {
                    this.confYn   = false;  //3자통화
                    this.trnsYn   = true;  //호전환
                    this.cnnClsYn = true;  //연결종료
                    this.SSTransferYn = false;
                } else {
                    this.confYn   = true;  //3자통화
                    this.trnsYn   = false;  //호전환
                    this.cnnClsYn = true;  //연결종료
                    this.SSTransferYn = true;
                }
                this.transferNum = item.CNSL_TNO;
                this.inlineNo    = item.CNSL_TNO;
            }
        },
        btnClick(item){
            console.log("+++++++"+ JSON.stringify(item));
            if (this.selectedRow != "") {
                this.selectedRow.select(false);
                this.selectedId="";
            }
            this.trnsYn   = true;  //호전환
            this.cnnClsYn = true;  //연결종료
            this.SSTransferYn = false;
            this.groupCd = item.group;

            if(this.selectedBtn === item){
                this.selectedBtn = null;
                this.transferNum = "";
                this.confYn   = true;  //3자통화
            }else{
                this.transferNum = "";
                this.selectedBtn = item;
                this.transferNum = item.text;
                this.transferNum = this.transferNum.replaceAll("-", "");

                if(this.transferNum >= 8 && this.transferNum.substr(0,2) == "02") {
                    this.transferNum = "9" + this.transferNum.substr(2); 
                }
                this.SSTransferYn = true;
                // this.confYn   = false;  //3자통화
                // if(item.group === "A") {
                    this.confYn   = true;  //3자통화
                    this.trnsYn   = false;  //호전환
                    this.cnnClsYn = true;  //연결종료
                // }
            }
        },
        confChkClick(val) {
            console.log("^^^" +  val + "/"+this.inlineNo.length);
            if(this.chkConf == "Y" && this.inlineNo.length >= 3) {
                this.confYn   = false; //3자통화
                this.trnsYn   = true;  //호전환
                this.cnnClsYn = true;  //연결종료
                this.SSTransferYn = false;
            } else if(this.inlineNo.length >= 3)  {
                this.confYn   = true;  //3자통화
                this.trnsYn   = false; //호전환
                this.cnnClsYn = true;  //연결종료
                this.SSTransferYn = true;
            } else {
                this.confYn   = true;  //3자통화
                this.trnsYn   = true;  //호전환
                this.cnnClsYn = true;  //연결종료
            }
        },
        keyDownInp(inpNo) {
            this.changeInp(inpNo);
        },
        changeInp(inpNo) {
            this.selectedBtn = null;
            if (this.selectedRow != "") {
                this.selectedRow.select(false);
                this.selectedId="";
            }

            if(inpNo.length >= 3) {
                if(this.chkConf === "Y") {
                    this.confYn   = false; //3자통화
                    this.trnsYn   = true;  //호전환
                    this.cnnClsYn = true;  //연결종료
                    this.SSTransferYn = false;
                } else {
                    this.confYn   = true;  //3자통화
                    this.trnsYn   = false; //호전환
                    this.cnnClsYn = true;  //연결종료
                    this.SSTransferYn = true;
                }
                this.transferNum = inpNo;
            } else {
                this.confYn   = true;  //3자통화
                this.trnsYn   = true;  //호전환
                this.cnnClsYn = true;  //연결종료
            }
        },
        chgState(val) {
            console.log(">>>"+ val);

            this.search = val;
        },
        fnConference: function(transNum) {
            if(this.mixin_isEmpty(transNum)) {
                this.common_alert(`연결할 전화번호가 없습니다.`, 'info');
                return false;
            }

            this.sendMsgToParent("CONSULT", transNum);
            //eventBus.ctiSendReq('consultCall', transNum);
        },
        fnSSTransfer: function(transNum) {
            if(this.mixin_isEmpty(transNum)) {
                this.common_alert(`연결할 전화번호가 없습니다.`, 'info');
                return false;
            }
            this.sendMsgToParent("SST", transNum);
            //eventBus.ctiSendReq('ssTransferCall', transNum);
        },
        /* 3자통화 버튼클릭 */
        fnConfBtnClick: function(e) {
            console.log("fnConfBtnClick BTN CLICK!!!" + this.transferNum + "/" + this.chkConf);
            this.fnConference(this.transferNum);

            this.confYn   = true; //3자통화
            this.trnsYn   = false;  //호전환
            this.cnnClsYn = false;  //연결종료
        },
        /* 호전환 버튼클릭 */
        fnTransfer: function(e) {
            console.log("fnTransfer BTN CLICK!!!transferNum grpCd:" +this.groupCd + "/"+ this.transferNum + "/SSTransferYn:" + this.SSTransferYn);
            if(this.groupCd == "A" || this.groupCd == "B" || this.groupCd == "C") {
                this.sendMsgToParent("SST", this.transferNum);
                this.close();
            } else if(this.SSTransferYn) {
                this.sendMsgToParent("SST", this.transferNum);
                this.close();
            } else {
                this.sendMsgToParent("TRANS", this.transferNum);
                this.close();
            }
            this.confYn   = true; //3자통화
            this.trnsYn   = true; //호전환
            this.cnnClsYn = false; //연결종료
            this.$store.dispatch('callStore/AC_CNSL_SAVE_YN', "N"); //상담저장여부

        },
        /* 연결시도 종료 버튼클릭  */
        fnConnClose: function(e) {
            console.log("fnConnClose BTN CLICK!!!");
            //eventBus.ctiSendReq('reconnect', "");
            this.sendMsgToParent("RECONN", this.transferNum);

            this.confYn   = true; //3자통화
            this.trnsYn   = true; //호전환
            this.cnnClsYn = true; //연결종료
        }
    },
    watch: { 
        message: function(nVal, oVal) {
            console.log("P100401 nVal>>>>"+ nVal);
            if(nVal == "consult") {
                this.confYn   = false; //3자통화
                this.trnsYn   = true; //호전환
                this.cnnClsYn = true; //연결종료
            } else if(nVal == "transfer") {
                this.confYn   = true; //3자통화
                this.trnsYn   = false; //호전환
                this.cnnClsYn = false; //연결종료
            } else if(nVal == "retrieve") {
                this.confYn   = true; //3자통화
                this.trnsYn   = true; //호전환
                this.cnnClsYn = true; //연결종료
            } else if(nVal == "tryConsult") {
                this.confYn   = true; //3자통화
                this.trnsYn   = false; //호전환
                this.cnnClsYn = true; //연결종료
            } else if(nVal == "teams") {
                console.log("rcv teams!!");
                //console.log("rcv DATA!!" + JSON.stringify(this.teamList));
                this.items = this.teamList;
                this.message = "succ";
            } else {
                console.log("$$ERR::" + nVal);
            }
            this.message = "";
        }
    }
};
</script>

<style></style>  